import React from 'react';
import { BarChart as BarChartPlot, Bar, LabelList, XAxis, ResponsiveContainer, LabelProps } from 'recharts';
import { BACK_COLOR, PRIME_COLOR, SECONDARY_COLOR } from 'constants/theme';
import { BarChartWrapper } from './BarChart.styled';

/* global ProveData */

interface Props {
  data: ProveData[];
}

function BarChart({ data }: Props) {
  return (
    <BarChartWrapper>
      <ResponsiveContainer width="100%" height={300}>
        <BarChartPlot data={data} barSize={40} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <XAxis tick={false} />

          <Bar dataKey="totalNum" stackId="a" fill={BACK_COLOR}>
            <LabelList dataKey="totalValue" position="right" offset={30} content={<CustomLabel />} />
          </Bar>

          <Bar dataKey="aiTrainingNum" stackId="a" fill={SECONDARY_COLOR}>
            <LabelList dataKey="aiTrainingValue" position="right" offset={30} content={<CustomLabel />} />
          </Bar>

          <Bar dataKey="aiProveNum" stackId="a" fill={PRIME_COLOR}>
            <LabelList dataKey="aiProveValue" position="right" offset={30} content={<CustomLabel />} />
          </Bar>
        </BarChartPlot>
      </ResponsiveContainer>
    </BarChartWrapper>
  );
}

function CustomLabel({ x, y, width, height, value }: LabelProps) {
  if (!value) return <g />;
  if (
    typeof x !== 'number' ||
    typeof y !== 'number' ||
    typeof width !== 'number' ||
    typeof height !== 'number' ||
    typeof value !== 'string'
  )
    return <g />;
    
  const [label, num] = JSON.parse(value);

  const labelOffset = 15;
  const boxOffset = 50 + label.length * 8;

  const boxWidth = 30 + num.toString().length * 9;
  const boxHeight = 30;

  const labelX = x + width + labelOffset;
  const labelY = y + height / 2;
  const boxX = x + width + boxOffset;
  const boxY = y + height / 2 - boxHeight / 2;
  const numX = boxX + boxWidth / 2;
  const numY = boxY + boxHeight / 2;

  return (
    <>
      <defs>
        <filter
          id="f3"
          x="0.5"
          y="0.6"
          width="150%"
          height="150%"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.229073 0 0 0 0 0.274695 0 0 0 0 0.365939 0 0 0 0.106288 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
      <g>
        <text x={labelX} y={labelY} fill={SECONDARY_COLOR} dominantBaseline="middle">
          {label}
        </text>
        <rect
          x={boxX}
          y={boxY}
          width={boxWidth}
          height={boxHeight}
          rx="4"
          fill="white"
          stroke="#F0F1F2"
          filter="url(#f3)"
        />
        <text x={numX} y={numY} fontSize="12px" fill={SECONDARY_COLOR} textAnchor="middle" dominantBaseline="middle">
          {num.toLocaleString()}
        </text>
      </g>
    </>
  );
}

export default BarChart;
