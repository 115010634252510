import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  BACK_COLOR,
  BACK_HOVER_COLOR,
  PRIME_COLOR,
  PRIME_HOVER_COLOR,
  SECONDARY_COLOR,
  SECONDARY_HOVER_COLOR,
} from 'constants/theme';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'prime' | 'secondary' | 'back';
  size?: 'middle' | 'small';
  link?: string;
}

function BasicButton({ link, children, ...props }: Props) {
  return link ? (
    <Link to={link}>
      <Button {...props}>{children}</Button>
    </Link>
  ) : (
    <Button {...props}>{children}</Button>
  );
}

const Button = styled.button<Props>`
  padding: 0.8em 1.1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  background-color: #ccc;
  box-shadow: 2px 2px 9px rgba(58, 70, 93, 0.106288);
  line-height: 1;
  font-weight: bold;
  font-size: 1rem;
  color: currentColor;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: #c6c6c6;
  }

  &:active {
    transform: translateZ(0) scale(0.98);
  }

  &:focus {
    outline: none;
  }

  ${props =>
    props.theme === 'prime' &&
    css`
      background-color: ${PRIME_COLOR};
      color: #fff;

      &:hover {
        background-color: ${PRIME_HOVER_COLOR};
      }
    `}

  ${props =>
    props.theme === 'secondary' &&
    css`
      background-color: ${SECONDARY_COLOR};
      color: #fff;

      &:hover {
        background-color: ${SECONDARY_HOVER_COLOR};
      }
    `}
  
  ${props =>
    props.theme === 'back' &&
    css`
      background-color: ${BACK_COLOR};
      color: #828d99;

      &:hover {
        background-color: ${BACK_HOVER_COLOR};
        color: #fff;
      }
    `}

  ${props =>
    props.size === 'middle' &&
    css`
      font-size: 1rem;
    `}

  ${props =>
    props.size === 'small' &&
    css`
      font-size: 0.8rem;
    `}
`;

export default BasicButton;
