import React from 'react';
import TitleContainer from 'components/organisms/TitleContainer/TitleContainer';
import { Wrapper, Information, DataLabel, DataDisplay } from './UserInformationContent.styled';

interface Props {
  gender?: string;
  age?: string;
  bodyType?: string;
}

function UserInformationContent({ gender, age, bodyType }: Props) {
  return (
    <TitleContainer title="예측시 입력된 데이터">
      <Wrapper>
        <Information>
          <DataLabel>성별</DataLabel>
          <DataDisplay>{gender}</DataDisplay>
        </Information>

        <Information>
          <DataLabel>연령</DataLabel>
          <DataDisplay>{age}</DataDisplay>
        </Information>

        <Information>
          <DataLabel>체형</DataLabel>
          <DataDisplay>{bodyType}</DataDisplay>
        </Information>
      </Wrapper>
    </TitleContainer>
  );
}

export default UserInformationContent;
