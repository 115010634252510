import React from 'react';
import DashboardTemplate from 'components/templates/DashboardTemplate/DashBoardTemplate';
import IngredientRecommendContent from 'components/organisms/Content/IngredientRecommendContent/IngredientRecommendContent';
import ProveDataContent from 'components/organisms/Content/ProveDataContent/ProveDataContent';
import BestModelContent from 'components/organisms/Content/BestModelContent/BestModelContent';
import { useAppSelector } from 'hooks/useRedux';

function DashBoard() {
  const dashboardStore = useAppSelector(state => state.dashboardReducer);

  return (
    <DashboardTemplate
      ingredientRecommendSection={
        <IngredientRecommendContent
          loading={dashboardStore.state === 'loading'}
          totalIngredient={dashboardStore.data?.ingredients}
          recommendMedicalData={dashboardStore.data?.totalData}
        />
      }
      proveDataSection={
        <ProveDataContent
          loading={dashboardStore.state === 'loading'}
          totalDataNum={dashboardStore.data?.totalData}
          proveDataNum={dashboardStore.data?.testData}
          trainDataNum={dashboardStore.data?.trainData}
          lastTrainDate={dashboardStore.data?.date}
        />
      }
      bestModelSection={
        <BestModelContent
          loading={dashboardStore.state === 'loading'}
          accuracy={dashboardStore.data?.metrics.accuracy}
          F1Score={dashboardStore.data?.metrics.F1Score}
          precision={dashboardStore.data?.metrics.precision}
          recall={dashboardStore.data?.metrics.recall}
        />
      }
    />
  );
}

export default DashBoard;
