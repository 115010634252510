import styled from 'styled-components';

interface Props {
  verticalAlign?: boolean;
  fullSize?: boolean;
  verticalCenter?: boolean;
  horizenCenter?: boolean;
}

export default styled.div<Props>`
  width: ${props => props.fullSize && '100%'};
  height: ${props => props.fullSize && '100%'};
  display: flex;
  flex-direction: ${props => props.verticalAlign && 'column'};
  justify-content: ${props =>
    ((!props.verticalAlign && props.verticalCenter) || (props.verticalAlign && props.horizenCenter)) && 'center'};
  align-items: ${props =>
    ((!props.verticalAlign && props.horizenCenter) || (props.verticalAlign && props.verticalCenter)) && 'center'};
`;
