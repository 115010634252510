import React, { useState } from 'react';
import Slider, { SliderProps } from '@mui/material/Slider';
import { Wrapper, CurrentValue } from './RangeSlider.styled';

interface Props extends SliderProps {}

function RangeSlider({ value, onChange, ...props }: Props) {
  const [sliderValue, setSliderValue] = useState<number | number[]>(50);

  const handleSlider = (event: Event, _value: number | number[], activeThumb: number): void => {
    if (onChange) {
      onChange(event, _value, activeThumb);
    }

    if (!value) {
      setSliderValue(_value);
    }
  };

  return (
    <Wrapper>
      <Slider value={value || sliderValue} {...props} onChange={handleSlider} />
      <CurrentValue>{value || sliderValue}%</CurrentValue>
    </Wrapper>
  );
}

export default RangeSlider;
