import React from 'react';
import Container from 'components/organisms/Container/Container';
import Flexbox from 'components/atoms/Flexbox';
import Title from 'components/atoms/Title';
import CircleChart from 'components/molecules/CircleChart/CircleChart';
import Vr from 'components/atoms/Vr';
import Loading from 'components/atoms/Loading';
import { CircleChartWrapper, LoadingBox } from './BestModelContent.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  accuracy?: number;
  F1Score?: number;
  precision?: number;
  recall?: number;
}

function BestModelContent({ loading, accuracy, F1Score, precision, recall, ...props }: Props): React.ReactElement {
  return (
    <Container {...props}>
      <Title style={{ marginBottom: '1.5rem' }}>Best Model</Title>

      {loading ? (
        <LoadingBox>
          <Loading size="50px" />
        </LoadingBox>
      ) : (
        <Flexbox style={{ padding: '2.5rem 0' }}>
          <CircleChartWrapper>
            <CircleChart data={{ name: 'Accuracy', percent: accuracy || 0 }} />
          </CircleChartWrapper>

          <Vr />

          <CircleChartWrapper>
            <CircleChart data={{ name: 'F1 Score', percent: F1Score || 0 }} />
          </CircleChartWrapper>

          <Vr />

          <CircleChartWrapper>
            <CircleChart data={{ name: 'Precision', percent: precision || 0 }} />
          </CircleChartWrapper>

          <Vr />

          <CircleChartWrapper>
            <CircleChart data={{ name: 'Recall', percent: recall || 0 }} />
          </CircleChartWrapper>
        </Flexbox>
      )}
    </Container>
  );
}

export default BestModelContent;
