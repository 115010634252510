import { RefObject } from 'react';

export function emitNativeChangeEvent(ref: RefObject<HTMLInputElement>, value: string | number) {
  const { current } = ref;
  if (!current) return;

  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
  nativeInputValueSetter?.call(current, value);

  const event = new InputEvent('input', { bubbles: true });
  current.dispatchEvent(event);
}
