import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { emitNativeChangeEvent } from 'utils/nativeEvent';
import { LINE_COLOR, SECONDARY_COLOR } from 'constants/theme';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: string[];
}

function SearchInput({ value, options, onChange, ...props }: Props) {
  const [inputValue, setInputValue] = useState('');
  const [isSearchShow, setIsSearchShow] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    setIsSearchShow(true);
  };

  const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    setIsSearchShow(false);
  };

  const handleInput: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (onChange) {
      onChange(event);
      return;
    }

    if (!value) {
      setInputValue(event.target.value);
    }
  };

  const handleItemClick: React.MouseEventHandler<HTMLLIElement> = event => {
    emitNativeChangeEvent(inputRef, event.currentTarget.innerText);
  };

  const handleSearchIconClick: React.MouseEventHandler<SVGSVGElement> = () => {
    const { current } = inputRef;
    if (!current) return;

    current.focus();
  };

  return (
    <InputWrapper>
      <Input
        {...props}
        ref={inputRef}
        value={value || inputValue}
        autoComplete="off"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleInput}
      />
      <SearchIcon width="18px" className="search-icon" onClick={handleSearchIconClick} />

      {isSearchShow && (
        <SearchListBox className="search-list custom-scroll-bar">
          {options?.map(item => (
            <SearchItem key={item} onMouseDown={handleItemClick}>
              {item}
            </SearchItem>
          ))}
        </SearchListBox>
      )}
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  position: relative;

  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    fill: ${SECONDARY_COLOR};
    cursor: pointer;
  }
`;

const Input = styled.input`
  width: 100%;
  margin: 0;
  padding: 0.5em 0.75em;
  padding-right: 35px;
  position: relative;
  border: none;
  border-bottom: 1px solid ${LINE_COLOR};
  background-color: transparent;
  box-sizing: border-box;
  font-size: 1rem;
  color: ${SECONDARY_COLOR};

  &:focus {
    outline: none;
  }
`;

const SearchListBox = styled.ul`
  width: 100%;
  max-height: 290px;
  position: absolute;
  bottom: -10px;
  left: 0;
  transform: translateY(100%);
  border: 1px solid ${LINE_COLOR};
  border-radius: 4px;
  background-color: #fff;
  overflow-y: scroll;
  box-shadow: -2px 2px 6px rgba(58, 70, 93, 0.12);
  z-index: 10;
`;

const SearchItem = styled.li`
  padding: 0.8rem 1rem;
  font-weight: 500;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }
`;

export default SearchInput;
