import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LoadingImage } from 'assets/images/loading.svg';

interface Props extends React.SVGAttributes<SVGSVGElement> {
  size?: string;
}

function Loading({ size, ...props }: Props) {
  return (
    <LoadingWrapper>
      <LoadingImage className="loading-image" {...props} width={size || '135px'} height={size || '135px'} />
    </LoadingWrapper>
  );
}

export default Loading;

const LoadingWrapper = styled.span`
  .loading-image {
    animation: rotate infinite 2s linear;

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
