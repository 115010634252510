import styled from 'styled-components';

export const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-template-areas:
    'ingredient provedata'
    'bestmodel bestmodel';
  grid-gap: 1.4rem;
`;

export const IngredientRecommendSection = styled.section`
  grid-area: ingredient;
`;

export const ProveDataSection = styled.section`
  grid-area: provedata;
`;

export const BestModelSection = styled.section`
  grid-area: bestmodel;
`;
