import styled from 'styled-components';
import { LINE_COLOR } from 'constants/theme';

export const Table = styled.table`
  width: 100%;
  margin-bottom: 4rem;
`;

export const TableHead = styled.th`
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 0.8rem;
  color: #828d99;

  &:nth-child(1) {
    width: 60%;
  }

  &:nth-child(2) {
    cursor: pointer;
  }
`;

export const TableHeadInner = styled.div`
  display: flex;
  align-items: center;

  .sort-icon {
    margin-left: 0.5rem;
  }
`;

export const TableRow = styled.tr`
  &:nth-child(even) td svg {
    fill: #ffd300;
  }

  &:nth-child(odd) td svg {
    fill: #73d500;
  }
`;

export const TableData = styled.td`
  padding: 1.2rem 0;
  border-bottom: 1px solid ${LINE_COLOR};
`;

export const IngredientName = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;

  svg {
    margin-right: 1.3rem;
  }
`;

export const IngredientValue = styled.div`
  display: flex;
  align-items: center;
`;
