import styled from 'styled-components';
import { PRIME_COLOR } from 'constants/theme';

export const ThresholdForm = styled.form`
  margin-bottom: 2.3rem;
  display: flex;
  align-items: center;
`;

export const ThresholdLabel = styled.label`
  margin-right: 1.6rem;
  font-size: 0.8rem;
`;

export const IngredientNum = styled.span`
  margin-left: 3.2rem;
  margin-right: 1.6rem;
  font-size: 0.8rem;
  color: ${PRIME_COLOR};

  b {
    margin-right: 0.1rem;
    font-size: 1.6rem;
  }
`;
