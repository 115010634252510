import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .fade-up-animation {
    position: relative;
    opacity: 0;
    visibility: hidden;
    transform: translateZ(0) translateY(10px);
    transition: all 0.6s linear;
  }

  .fade-up {
    transform: translateZ(0) translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;