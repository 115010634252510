export const INIT_SELECT_LIST_DATA = 'INIT_SELECT_LIST_DATA';
export const SET_SELECT_LIST_DATA = 'SET_SELECT_LIST_DATA';
export const ERROR_SELECT_LIST_DATA = 'ERROR_SELECT_LIST_DATA';

/* global SelectListData, InitSelectListAction, SetSelectListAction, ErrorSelectListAction */

export const initSelectListData = (): InitSelectListAction => ({
  type: INIT_SELECT_LIST_DATA,
});

export const setSelectListData = (data: SelectListData): SetSelectListAction => ({
  type: SET_SELECT_LIST_DATA,
  payload: data,
});

export const errorSelectListData = (): ErrorSelectListAction => ({
  type: ERROR_SELECT_LIST_DATA,
});
