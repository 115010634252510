import React from 'react';
import { LINE_COLOR, SECONDARY_COLOR } from 'constants/theme';
import styled from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

function Radio({ id, children, ...props }: Props) {
  return (
    <>
      <HiddenInput id={id} type="radio" {...props} />
      <RadioCircle htmlFor={id} />
      <Label htmlFor={id}>{children}</Label>
    </>
  );
}

const RadioCircle = styled.label`
  width: 14px;
  height: 14px;
  position: relative;
  display: inline-block;
  border: 1px solid ${LINE_COLOR};
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;

  &::before {
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateZ(0) translateX(-50%) translateY(-50%) scale(0);
    border-radius: 50%;
    background-color: ${SECONDARY_COLOR};
    transition: 0.3s ease;
  }
`;

const HiddenInput = styled.input`
  display: none;

  &:checked + label::before {
    transform: translateZ(0) translateX(-50%) translateY(-50%) scale(1);
  }
`;

const Label = styled.label`
  margin-left: 0.5rem;
  margin-right: 1rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 160%;
  cursor: pointer;
`;

export default Radio;
