import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
}

export default styled.img<Props>`
  width: ${props => props.width};
  height: ${props => props.height};
`;
