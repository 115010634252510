import React from 'react';
import Counter from 'react-countup';

interface Props {
  value: number;
}

function NumberCounter({ value }: Props) {
  const options = {
    duration: 3,
    seperator: ',',
    useEasing: true,
  };

  return <Counter end={value} style={{ fontWeight: 'bold', fontSize: '1.6rem' }} {...options} />;
}

export default NumberCounter;
