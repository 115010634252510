import React from 'react';
import { TemplateGrid, IngredientRecommendSection, ProveDataSection, BestModelSection } from './DashBoardTemplate.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  ingredientRecommendSection: React.ReactNode;
  proveDataSection: React.ReactNode;
  bestModelSection: React.ReactNode;
}

function DashboardTemplate({
  ingredientRecommendSection,
  proveDataSection,
  bestModelSection,
  ...props
}: Props): React.ReactElement {
  return (
    <TemplateGrid {...props}>
      <IngredientRecommendSection>{ingredientRecommendSection}</IngredientRecommendSection>
      <ProveDataSection>{proveDataSection}</ProveDataSection>
      <BestModelSection>{bestModelSection}</BestModelSection>
    </TemplateGrid>
  );
}

export default DashboardTemplate;
