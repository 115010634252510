import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { emitNativeChangeEvent } from 'utils/nativeEvent';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down.svg';
import { LINE_COLOR } from 'constants/theme';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value?: string;
  options?: string[];
  placeholder?: string;
}

function Select({ value, options, placeholder, onChange, ...props }: Props) {
  const [selectValue, setSelectValue] = useState('');
  const [isOptionBoxShow, setIsOptionBoxShow] = useState(false);

  const displayValue = useMemo(() => value || selectValue, [value, selectValue]);

  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const handleSelectBoxFocus: React.FocusEventHandler<HTMLDivElement> = () => {
    setIsOptionBoxShow(true);
  };

  const handleSelectBoxBlur: React.FocusEventHandler<HTMLDivElement> = () => {
    setIsOptionBoxShow(false);
  };

  const handleSelectChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (onChange) {
      onChange(event);
      return;
    }

    setSelectValue(event.target.value);
    setIsOptionBoxShow(false);
  };

  const handleItemClick: React.MouseEventHandler<HTMLLIElement> = event => {
    const { innerText } = event.currentTarget;
    emitNativeChangeEvent(hiddenInputRef, innerText);
  };

  return (
    <SelectWrapper>
      <SelectBox
        {...props}
        isShow={isOptionBoxShow}
        tabIndex={0}
        onFocus={handleSelectBoxFocus}
        onBlur={handleSelectBoxBlur}
      >
        <SelectValue isEmpty={!displayValue}>{displayValue || placeholder}</SelectValue>

        <ArrowIcon fill={selectValue ? '#828D99' : '#BBC0C5'} />
      </SelectBox>

      {isOptionBoxShow && (
        <SelectOptionBox>
          {options?.map(item => (
            <SelectOptionItem key={item} onMouseDown={handleItemClick}>
              {item}
            </SelectOptionItem>
          ))}
        </SelectOptionBox>
      )}

      <input {...props} ref={hiddenInputRef} type="text" hidden onChange={handleSelectChange} />
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  position: relative;
`;

const SelectBox = styled.div<{ isShow?: boolean }>`
  width: 110px;
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  border: 1px solid ${LINE_COLOR};
  border-radius: ${props => (props.isShow ? '4px 4px 0 0' : '4px')};
  background-color: #fff;
  font-weight: 500;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: ${props => (props.isShow ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)')};
    right: 10px;
    transition: 0.4s ease;
  }
`;

const SelectValue = styled.span<{ isEmpty?: boolean }>`
  color: ${props => props.isEmpty && '#BBC0C5'};
`;

const SelectOptionBox = styled.ul`
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
  border: 1px solid ${LINE_COLOR};
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: -2px 2px 6px rgba(58, 70, 93, 0.12);
  cursor: auto;
  z-index: 10;
`;

const SelectOptionItem = styled.li`
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }
`;

export default Select;
