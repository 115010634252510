import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TitleContainer from 'components/organisms/TitleContainer/TitleContainer';
import RangeSlider from 'components/molecules/RangeSlider/RangeSlider';
import BasicButton from 'components/atoms/BasicButton';
import Hr from 'components/atoms/Hr';
import IngredientTable from 'components/organisms/IngredientTable/IngredientTalbe';
import { useAppSelector } from 'hooks/useRedux';
import { ThresholdForm, ThresholdLabel, IngredientNum } from './ResultRecommend.styled';

/* global RecommendIngredient */

interface Props {
  threshold?: number;
}

function ResultRecommend({ threshold }: Props) {
  const history = useHistory();

  const inferenceStore = useAppSelector(state => state.inferenceReducer);
  if (inferenceStore.state === 'prepare') {
    history.replace('/prediction');
  }

  const [recommendIngredient, setRecommendIngredient] = useState<RecommendIngredient[]>([]);
  const [thresholdRangeValue, setThresholdRangeValue] = useState(threshold || 0);

  const thresholdValue = useRef(thresholdRangeValue);

  const handleIngredient = useCallback(() => {
    if (!inferenceStore.data) return;

    const filterData = inferenceStore.data.filter(ingredient => ingredient.value >= thresholdValue.current * 0.01);
    setRecommendIngredient(filterData);
  }, [inferenceStore]);

  const handleThreshold = (event: Event, value: number | number[]) => {
    if (!Array.isArray(value)) {
      setThresholdRangeValue(value);
      thresholdValue.current = value;
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    handleIngredient();
  };

  useEffect(() => {
    handleIngredient();
  }, [handleIngredient]);

  return (
    <TitleContainer title="추천 성분">
      <ThresholdForm onSubmit={handleSubmit}>
        <ThresholdLabel>추천 지수</ThresholdLabel>

        <RangeSlider max={100} step={5} value={thresholdRangeValue} onChange={handleThreshold} />

        <IngredientNum>
          <b>{recommendIngredient.length}</b>가지
        </IngredientNum>

        <BasicButton theme="prime" size="small">
          적용하기
        </BasicButton>
      </ThresholdForm>

      <Hr />

      <IngredientTable ingredientData={recommendIngredient} style={{ marginTop: '3.2rem' }} />
    </TitleContainer>
  );
}

export default ResultRecommend;
