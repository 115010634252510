import React from 'react';
import { useLocation } from 'react-router-dom';
import ResultTemplate from 'components/templates/ResultTemplate/ResultTemplate';
import UserInformationContent from 'components/organisms/Content/UserInformationContent/UserInformationContent';
import ResultRecommend from 'components/organisms/ResultRecommend/ResultRecommend';

interface LocationState {
  threshold?: number;
  userInfo?: {
    gender?: string;
    age?: string;
    bodyType?: string;
  };
}

function PredictionResult() {
  const location = useLocation<LocationState>();
  const { userInfo } = location.state;
  const { threshold } = location.state;

  return (
    <ResultTemplate
      userInfoSection={
        <UserInformationContent gender={userInfo?.gender} age={userInfo?.age} bodyType={userInfo?.bodyType} />
      }
      recommendSection={<ResultRecommend threshold={threshold} />}
    />
  );
}

export default PredictionResult;
