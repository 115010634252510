export const INIT_DASHBOARD_DATA = 'INIT_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const ERROR_DASHBOARD_DATA = 'ERROR_DASHBOARD_DATA';

/* global DashboardData, InitDashboardAction, SetDashboardAction, ErrorDashboardAction */

export const initDashboardData = (): InitDashboardAction => ({
  type: INIT_DASHBOARD_DATA,
});

export const setDashboardData = (data: DashboardData): SetDashboardAction => ({
  type: SET_DASHBOARD_DATA,
  payload: data,
});

export const errorDashboardData = (): ErrorDashboardAction => ({
  type: ERROR_DASHBOARD_DATA,
})