import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 2.5rem;

  .container-title {
    margin-bottom: 1.25rem;
    padding-top: 1.5rem;
    font-size: 0.8rem;
  }
`;
