import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
`;

export const ResetButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  .reset-icon {
    fill: #fff;
    transition: 0.4s ease;
  }

  .reset-btn:hover .reset-icon {
    transform: rotate(-270deg);
  }
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const InputBox = styled.div`
  margin-right: 3.2rem;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  .prediction-range-input {
    max-width: 400px;
  }
`;

export const InputLabel = styled.label`
  margin-right: 1.6rem;
  white-space: nowrap;
  font-size: 0.8rem;
`;

export const SubmitButtonWrapper = styled.div`
  .submit-icon {
    margin-right: 0.35em;
    fill: #fff;
  }
`;

export const LoadingWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoadingMessage = styled.p`
  margin-bottom: 2.1rem;
  font-weight: 500;
  font-size: 1.3rem;
`;