import styled from "styled-components";

interface Props {
  width?: string;
}

export default styled.div<Props>`
  width: 0px;
  float: left;
  border-left: 1px solid #e1e5e9;
  border-width: ${props => props.width};
`;