import { INIT_DASHBOARD_DATA, setDashboardData, errorDashboardData } from 'actions/dashboard';
import { fetchDashboard } from 'apis/fetchDashboard';
import { call, put, takeLatest } from 'redux-saga/effects';

/* global DashboardData */

function* initDashboardDataSaga() {
  try {
    const data: DashboardData = yield call(fetchDashboard);
    yield put(setDashboardData(data));
  } catch (error) {
    yield put(errorDashboardData());
  }
}

export function* dashboardSaga() {
  yield takeLatest(INIT_DASHBOARD_DATA, initDashboardDataSaga);
}
