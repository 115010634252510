import { INIT_DASHBOARD_DATA, SET_DASHBOARD_DATA, ERROR_DASHBOARD_DATA } from 'actions/dashboard';

/* global DashboardState, InitDashboardAction, SetDashboardAction, ErrorDashboardAction */

const initState: DashboardState = {
  state: 'prepare',
};

type DashboardActions = InitDashboardAction | SetDashboardAction | ErrorDashboardAction;

const dashboardReducer = (state = initState, actions: DashboardActions): DashboardState => {
  switch (actions.type) {
    case INIT_DASHBOARD_DATA:
      return { ...state, state: 'loading' };
    case SET_DASHBOARD_DATA:
      return { ...state, state: 'complete', data: actions.payload };
    case ERROR_DASHBOARD_DATA:
      return { ...state, state: 'error' };
    default:
      return state;
  }
};

export default dashboardReducer;
