import { combineReducers } from 'redux';
import dashboardReducer from 'reducers/dashboard';
import selectListReducer from 'reducers/selectlist';
import inferenceReducer from 'reducers/inference';

export const rootReducer = combineReducers({
  dashboardReducer,
  selectListReducer,
  inferenceReducer,
});
