import React from 'react';
import Container from 'components/organisms/Container/Container';
import Title from 'components/atoms/Title';
import BarChart from 'components/molecules/BarChart/BarChart';
import Loading from 'components/atoms/Loading';
import { LastTrainingDate, LoadingBox } from './ProveDataContent.styled';

/* global ProveData */

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  totalDataNum?: number;
  proveDataNum?: number;
  trainDataNum?: number;
  lastTrainDate?: string;
}

function ProveDataContent({
  loading,
  totalDataNum,
  proveDataNum,
  trainDataNum,
  lastTrainDate,
  ...props
}: Props): React.ReactElement {
  const createChartData = (totalData: number, proveData: number, trainData: number): ProveData[] => [
    {
      name: '총 데이터',
      totalValue: JSON.stringify(['총 데이터', totalData]),
      totalNum: totalData,
    },
    {
      empty: true,
    },
    {
      empty: true,
    },
    {
      empty: true,
    },
    {
      name: 'AI 데이터',
      aiProveValue: JSON.stringify(['AI 검증 데이터', proveData]),
      aiProveNum: proveData,
      aiTrainingValue: JSON.stringify(['AI 학습 데이터', trainData]),
      aiTrainingNum: trainData,
    },
    {
      empty: true,
    },
    {
      empty: true,
    },
    {
      empty: true,
    },
  ];

  return (
    <Container height="400px" {...props}>
      <Title>검증 데이터 현황</Title>

      {loading ? (
        <LoadingBox>
          <Loading size="40px" />
        </LoadingBox>
      ) : (
        <>
          <BarChart data={createChartData(totalDataNum || 0, proveDataNum || 0, trainDataNum || 0)} />

          <LastTrainingDate>
            <h1>마지막 학습일</h1>
            <h2>{lastTrainDate}</h2>
          </LastTrainingDate>
        </>
      )}
    </Container>
  );
}

export default ProveDataContent;
