import React from 'react';
import { NavLink } from 'react-router-dom';
import Title from 'components/atoms/Title';
import Text from 'components/atoms/Text';
import NavLogo from 'assets/icons/logo.png';
import Icon from 'components/atoms/Icon';
import { SECONDARY_COLOR } from 'constants/theme';
import { ReactComponent as ForecaseIcon } from 'assets/icons/forecast.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/overview.svg';
import { Navigation, NavigationHeader, NavigationList, NavigationItemWrapper, Content } from './NavLayout.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function NavLayout({ children, ...props }: Props) {
  return (
    <>
      <Navigation {...props}>
        <NavigationHeader>
          <Icon width="24px" style={{ marginRight: '0.6rem' }} src={NavLogo} alt="main-logo" />
          <Title fontFamiliy="Roboto" fontSize="0.8rem" fontWeight="bold">
            P.I. RECOMMENDATION
          </Title>
        </NavigationHeader>

        <NavigationList>
          <NavigationItem
            to="/"
            name="성분 추천 현황"
            navIcon={<OverviewIcon style={{ marginRight: '0.6rem' }} fill={SECONDARY_COLOR} />}
          />

          <NavigationItem
            to="/prediction"
            name="개별 추천 성분 예측"
            navIcon={<ForecaseIcon style={{ marginRight: '0.6rem' }} fill={SECONDARY_COLOR} />}
          />
        </NavigationList>
      </Navigation>

      <Content>{children}</Content>
    </>
  );
}

interface NavigationItemProp {
  to: string;
  name: string;
  navIcon: React.ReactNode;
}

function NavigationItem({ to, name, navIcon }: NavigationItemProp) {
  return (
    <NavLink to={to} className="nav-link" exact activeClassName="nav-active">
      <NavigationItemWrapper>
        {navIcon}
        <Text className="nav-item-name">{name}</Text>
      </NavigationItemWrapper>
    </NavLink>
  );
}

export default NavLayout;
