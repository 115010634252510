import React from 'react';
import Container from 'components/organisms/Container/Container';
import Flexbox from 'components/atoms/Flexbox';
import Title from 'components/atoms/Title';
import Icon from 'components/atoms/Icon';
import Hr from 'components/atoms/Hr';
import NumberCounter from 'components/atoms/NumberCounter';
import Loading from 'components/atoms/Loading';
import { useFadeUp } from 'hooks/useAnimation';
import DrugBottleIcon from 'assets/icons/drug_bottle.png';
import FirstAidKit from 'assets/icons/first_aid_kit.png';
import { DataBox, LoadingBox } from './IngredientRecommendContent.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  totalIngredient?: number;
  recommendMedicalData?: number;
}

function IngredientRecommendContent({
  loading,
  totalIngredient,
  recommendMedicalData,
  ...props
}: Props): React.ReactElement {
  const fadeAttr = useFadeUp();

  return (
    <Container height="400px" {...props}>
      <Flexbox fullSize verticalAlign horizenCenter>
        <Title style={{ marginBottom: '1.5rem' }}>성분 추천 현황</Title>

        {loading ? (
          <LoadingBox>
            <Loading size="40px" />
          </LoadingBox>
        ) : (
          <div {...fadeAttr}>
            <DataBox>
              <Icon width="19px" style={{ marginRight: '1.6rem' }} src={DrugBottleIcon} alt="ingredient-icon" />
              <p style={{ marginRight: 'auto' }}>총 성분 개수</p>
              <NumberCounter value={totalIngredient || 0} />
            </DataBox>

            <Hr />

            <DataBox>
              <Icon width="22px" style={{ marginRight: '1.6rem' }} src={FirstAidKit} alt="ingredient-icon" />
              <p style={{ marginRight: 'auto' }}>추천 처방 데이터</p>
              <NumberCounter value={recommendMedicalData || 0} />
            </DataBox>
          </div>
        )}
      </Flexbox>
    </Container>
  );
}

export default IngredientRecommendContent;
