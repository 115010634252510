import styled from 'styled-components';
import { SECONDARY_COLOR } from 'constants/theme';

export const Wrapper = styled.div`
  width: 360px;
  display: flex;
  align-items: center;

  .MuiSlider-colorPrimary {
    color: ${SECONDARY_COLOR};
  }

  .MuiSlider-rail {
    opacity: 0.2;
  }

  .MuiSlider-thumb {
    width: 14px;
    height: 14px;
    border: 3px solid #fff;
  }

  .MuiSlider-thumb:hover,
  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 6px rgb(48 65 86 / 16%);
  }

  .MuiSlider-thumb.Mui-active {
    box-shadow: 0px 0px 0px 10px rgb(48 65 86 / 16%);
  }
`;

export const CurrentValue = styled.div`
  margin-left: 1.5rem;
  font-weight: 500;
`;