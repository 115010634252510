import styled from 'styled-components';

export const LastTrainingDate = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    font-weight: 500;
    font-size: 0.8rem;
    color: #828d99;
  }

  h2 {
    font-weight: 500;
    font-size: 0.8rem;
    color: #304156;
  }
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
