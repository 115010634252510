import React from 'react';
import Title from 'components/atoms/Title';
import Container from 'components/organisms/Container/Container';
import { Wrapper } from './TitleContainer.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}

function TitleContainer({ title, children, ...props }: Props) {
  return (
    <Wrapper {...props}>
      <Title className="container-title">{title}</Title>
      <Container>{children}</Container>
    </Wrapper>
  );
}

export default TitleContainer;
