import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
}

export const ContainerWrapper = styled.div<Props>`
  width: ${props => props.width};
  height: ${props => props.height};
  padding: 1.6rem 2.26rem;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.0962358);
`;
