import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import NavLayout from 'Layout/NavLayout/NavLayout';
import DashBoard from 'pages/DashBoard';
import Prediction from 'pages/Prediction';
import PredictionResult from 'pages/Prediction/Result';

function AppRouter(): React.ReactElement {
  return (
    <BrowserRouter>
      <NavLayout>
        <Switch>
          <Route path="/prediction/result">
            <PredictionResult />
          </Route>

          <Route path="/prediction">
            <Prediction />
          </Route>

          <Route exact path="/">
            <DashBoard />
          </Route>

          <Redirect to="/" />
        </Switch>
      </NavLayout>
    </BrowserRouter>
  );
}

export default AppRouter;
