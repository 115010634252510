import { errorSelectListData, INIT_SELECT_LIST_DATA, setSelectListData } from 'actions/selectlist';
import { fetchSelectList } from 'apis/fetchSelectList';
import { call, put, takeLatest } from 'redux-saga/effects';

/* global SelectListData */

function* initSelectListDataSaga() {
  try {
    const data: SelectListData = yield call(fetchSelectList);
    yield put(setSelectListData(data));
  } catch (error) {
    yield put(errorSelectListData());
  }
}

export function* selectListSaga() {
  yield takeLatest(INIT_SELECT_LIST_DATA, initSelectListDataSaga);
}
