import styled from 'styled-components';
import { LINE_COLOR, PRIME_COLOR } from 'constants/theme';
import { floatToPercent } from 'utils/convert';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CurrentPercent = styled.span`
  margin-right: 1rem;
  font-weight: 700;
  font-size: 1rem;
  color: ${PRIME_COLOR};
`;

export const ProgressWrapper = styled.div`
  flex: 1;
  height: 4px;
  position: relative;
  display: flex;
  border-radius: 30px;
  background-color: ${LINE_COLOR};
`;

export const ProgressValue = styled.span<{ value: number }>`
  width: ${props => floatToPercent(props.value)};
  height: 4px;
  display: inline-block;
  border-radius: 30px;
  background-color: ${PRIME_COLOR};
  transition: width 1s ease;
`;
