import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Information = styled.div`
  margin-right: 3.2rem;
`;

export const DataLabel = styled.span`
  margin-right: 1.6rem;
  font-size: 0.8rem;
`;

export const DataDisplay = styled.span`
  font-weight: 500;
  font-size: 1rem;
`;