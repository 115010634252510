import axios from 'axios';
import { API_ADDRESS } from 'constants/server';

/* global SelectListData */

export async function fetchSelectList(): Promise<SelectListData> {
  const response = await axios.get(`${API_ADDRESS}/list`);
  const data: SelectListData = {
    ...response.data,
    bodyType: response.data.mass,
  }

  return data;
}
