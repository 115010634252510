import styled from 'styled-components';
import { NAVIGATION_WIDTH } from 'constants/layout';
import { PRIME_COLOR } from 'constants/theme';

export const Navigation = styled.nav`
  width: ${NAVIGATION_WIDTH};
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  border-right: 1px solid #f0f1f2;
  background-color: #fff;
  z-index: 100;
`;

export const NavigationHeader = styled.header`
  padding: 1.5rem 1.7rem;
  display: flex;
  align-items: center;
  color: ${PRIME_COLOR};
`;

export const NavigationList = styled.ul`
  .nav-link {
    display: block;
    text-decoration: inherit;
    color: inherit;
  }

  .nav-active {
    background-color: #f2f4f4;
  }
`;

export const NavigationItemWrapper = styled.li`
  padding: 0.8rem 1.7rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover .nav-item-name {
    font-weight: 600;
  }
`;

export const Content = styled.main`
  width: calc(100vw - ${NAVIGATION_WIDTH});
  min-width: 1048px;
  margin-left: ${NAVIGATION_WIDTH};
  padding: 3.7rem 2.5rem;
`;
