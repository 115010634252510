import React, { useEffect } from 'react';
import AppRouter from 'components/Router';
import { useAppDispatch } from 'hooks/useRedux';
import { initDashboardData } from 'actions/dashboard';
import { initSelectListData } from 'actions/selectlist';

function App(): React.ReactElement {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initDashboardData());   // 대쉬보드 데이터 fetch 리덕스 액션 (saga 연동)
    dispatch(initSelectListData());  // 셀렉트 박스 리스트 데이터 fetch 리덕스 액션 (saga 연동)
  }, [dispatch]);

  return <AppRouter />;
}

export default App;
