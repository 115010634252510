import styled from 'styled-components';

interface Props {
  textCenter?: boolean;
  fontFamiliy?: 'Roboto' | 'NotoSans';
  fontWeight?: string;
  fontSize?: string;
  color?: string;
}

export default styled.h1<Props>`
  text-align: ${props => props.textCenter && 'center'};
  font-family: ${props => props.fontFamiliy === 'NotoSans' && "'Noto Sans KR', sans-serif"};
  font-family: ${props => props.fontFamiliy === 'Roboto' && "'Roboto', sans-serif"};
  font-weight: ${props => props.fontWeight || '500'};
  font-size: ${props => props.fontSize || '1rem'};
  color: ${props => props.color};
`;
