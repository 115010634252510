import React, { useEffect, useState } from 'react';
import { floatToPercent } from 'utils/convert';
import { Wrapper, CurrentPercent, ProgressWrapper, ProgressValue } from './ProgressBar.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  percent: number;
}

function ProgressBar({ percent, ...props }: Props) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(percent);
  }, [percent]);

  return (
    <Wrapper {...props}>
      <CurrentPercent>{floatToPercent(percent)}</CurrentPercent>
      <ProgressWrapper>
        <ProgressValue value={value} />
      </ProgressWrapper>
    </Wrapper>
  );
}

export default ProgressBar;
