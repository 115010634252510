import styled from 'styled-components';
import { LINE_COLOR } from 'constants/theme';

interface Props {
  width?: string;
}

export default styled.hr<Props>`
  margin: 0;
  border: none;
  border-top: 1px solid ${LINE_COLOR};
  border-width: ${props => props.width};
`;
