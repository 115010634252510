import { SET_INFERENCE_DATA } from 'actions/inference';

/* global InferenceState, SetInferenceAction */

const initState: InferenceState = {
  state: 'prepare',
};

type InferenceActions = SetInferenceAction;

const inferenceReducer = (state = initState, actions: InferenceActions): InferenceState => {
  switch (actions.type) {
    case SET_INFERENCE_DATA:
      return { ...state, state: 'complete', data: actions.payload };
    default:
      return state;
  }
};

export default inferenceReducer;
