import axios from 'axios';
import { API_ADDRESS } from 'constants/server';

/* global DashboardData */

export async function fetchDashboard(): Promise<DashboardData> {
  const response = await axios.get(`${API_ADDRESS}/init`);
  const data: DashboardData = {
    ingredients: response.data.data.ingredients,
    totalData: response.data.data.total_data,
    trainData: response.data.data.train_data,
    testData: response.data.data.test_data,
    date: response.data.data.date,
    metrics: {
      accuracy: response.data.data.metrics.Accuracy,
      F1Score: response.data.data.metrics.F1_Score,
      precision: response.data.data.metrics.Precision,
      recall: response.data.data.metrics.Recall,
    },
  };

  return data;
}
