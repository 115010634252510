import React, { useMemo } from 'react';
import { BACK_COLOR, PRIME_COLOR, SECONDARY_COLOR } from 'constants/theme';
import { PieChart, Pie, Sector, SectorProps, ResponsiveContainer } from 'recharts';

/* global BestModelData */

interface CustomSectorProps extends SectorProps {
  payload?: BestModelData;
  value?: number;
}

interface Props {
  data: BestModelData;
}

function CircleChart({ data }: Props) {
  const pieData = useMemo(() => {
    const result: BestModelData[] = [{ ...data }, { ...data }];
    result[1].percent = 1 - result[1].percent;
    return result;
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={230}>
      <PieChart>
        <Pie
          data={pieData}
          dataKey="percent"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius={93}
          outerRadius={100}
          fill={BACK_COLOR}
          activeShape={<CustomSector />}
          activeIndex={0}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

function CustomSector({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload, value }: CustomSectorProps) {
  if (!cx || !cy || !innerRadius || !outerRadius || !startAngle || !endAngle || !payload || !value) return <g />;

  return (
    <g>
      <text x={cx} y={cy - 35} dy={8} textAnchor="middle" dominantBaseline="middle" fontWeight="500" fill={SECONDARY_COLOR}>
        {payload.name}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontSize="36px"
        fill={SECONDARY_COLOR}
      >
        {`${Math.round(value * 10000) / 100}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={PRIME_COLOR}
      />
    </g>
  );
}

export default CircleChart;
