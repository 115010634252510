import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import TitleContainer from 'components/organisms/TitleContainer/TitleContainer';
import SearchInput from 'components/atoms/SearchInput';
import Radio from 'components/atoms/Radio';
import Select from 'components/atoms/Select';
import BasicButton from 'components/atoms/BasicButton';
import RangeSlider from 'components/molecules/RangeSlider/RangeSlider';
import Loading from 'components/atoms/Loading';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { fetchInference } from 'apis/fetchInference';
import { setInferenceData } from 'actions/inference';
import { filterWord } from 'utils/filter';
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import { ReactComponent as ForecastIcon } from 'assets/icons/forecast.svg';
import {
  Form,
  ResetButtonWrapper,
  InputWrapper,
  InputBox,
  InputLabel,
  SubmitButtonWrapper,
  LoadingWrapper,
  LoadingMessage,
} from './PredictionForm.styled';

/* global SelectListData, InferenceApiBody */

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {}

interface UserInfo {
  gender: string;
  age: string;
  bodyType: string;
}

interface TakingMedicine {
  hyperlipidemia: string;
  diabetes: string;
  bloodPressure: string;
}

interface PredictionFormData {
  userInfo: UserInfo;
  disease: string[];
  symptom: string[];
  takingMedicine: TakingMedicine;
  threshold: number;
}

interface UserInfoProp {
  userInfo: UserInfo;
  selectList?: SelectListData;
  handleUserInfo: React.ChangeEventHandler<HTMLInputElement>;
}

interface DiseaseProp {
  disease: string[];
  selectList: string[][];
  handleDisease: React.ChangeEventHandler<HTMLInputElement>;
}

interface SymptomProp {
  symptom: string[];
  selectList: string[][];
  handleSymptom: React.ChangeEventHandler<HTMLInputElement>;
}

interface TakingMedicineProp {
  takingMedicine: TakingMedicine;
  handleTakingMedicine: React.ChangeEventHandler<HTMLInputElement>;
}

interface ThresholdProp {
  threshold: number;
  handleThreshold: (event: Event, _value: number | number[], activeThumb: number) => void;
}

function PredictionForm({ ...props }: Props) {
  const [userInfo, setUserInfo] = useState<UserInfo>({ gender: '', age: '', bodyType: '' });
  const [disease, setDisease] = useState(['없음', '없음', '없음', '없음']);
  const [symptom, setSymptoms] = useState(['없음', '없음', '없음', '없음']);
  const [takingMedicine, setTakingMedicine] = useState<TakingMedicine>({
    hyperlipidemia: '',
    diabetes: '',
    bloodPressure: '',
  });
  const [threshold, setThreshold] = useState(50);

  const [diseaseOptions, setDiseaseOptions] = useState([[''], [''], [''], ['']]);
  const [symptomOptions, setSymptomOptions] = useState([[''], [''], [''], ['']]);

  const [loading, setLoading] = useState(false);

  const selectListStore = useAppSelector(state => state.selectListReducer);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const resetPage = (): void => {
    window.location.reload();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();

    if (!verifyInferenceBodyType({ userInfo, disease, symptom, takingMedicine, threshold })) return;

    setLoading(true);
    try {
      const body = createInferenceBody({ userInfo, disease, symptom, takingMedicine, threshold });
      const inferenceResponse = await fetchInference(body);
      dispatch(setInferenceData(inferenceResponse));

      history.push('/prediction/result', { threshold, userInfo });
    } catch (error) {
      setLoading(false);

      const axiosError = error as AxiosError;
      if (!axiosError.response) {
        alert('Network Error');
        return;
      }

      const { status, statusText } = axiosError.response;
      alert(`Error: ${status}(${statusText})`);
    }
  };

  const handleUserInfo: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const { value } = event.target;

      if (event.target.name === 'gender') {
        setUserInfo({ ...userInfo, gender: value });
      }
      if (event.target.id === 'age') {
        setUserInfo({ ...userInfo, age: value });
      }
      if (event.target.id === 'body-type') {
        setUserInfo({ ...userInfo, bodyType: value });
      }
    },
    [userInfo]
  );

  const handleDisease: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const { value } = event.target;
      const newDisease = [...disease];
      const newDiseaseOptions = [...diseaseOptions];

      if (!selectListStore.data) return;

      if (event.target.id === 'disease-1') {
        newDisease[0] = value;
        newDiseaseOptions[0] = filterWord(value, selectListStore.data.disease);
      }
      if (event.target.id === 'disease-2') {
        newDisease[1] = value;
        newDiseaseOptions[1] = filterWord(value, selectListStore.data.disease);
      }
      if (event.target.id === 'disease-3') {
        newDisease[2] = value;
        newDiseaseOptions[2] = filterWord(value, selectListStore.data.disease);
      }
      if (event.target.id === 'disease-4') {
        newDisease[3] = value;
        newDiseaseOptions[3] = filterWord(value, selectListStore.data.disease);
      }
      setDisease(newDisease);
      setDiseaseOptions(newDiseaseOptions);
    },
    [disease, diseaseOptions, selectListStore]
  );

  const handleSymptom: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const { value } = event.target;
      const newSymptom = [...symptom];
      const newSymptomOptions = [...symptomOptions];

      if (!selectListStore.data) return;

      if (event.target.id === 'symptom-1') {
        newSymptom[0] = value;
        newSymptomOptions[0] = filterWord(value, selectListStore.data.symptom);
      }
      if (event.target.id === 'symptom-2') {
        newSymptom[1] = value;
        newSymptomOptions[1] = filterWord(value, selectListStore.data.symptom);
      }
      if (event.target.id === 'symptom-3') {
        newSymptom[2] = value;
        newSymptomOptions[2] = filterWord(value, selectListStore.data.symptom);
      }
      if (event.target.id === 'symptom-4') {
        newSymptom[3] = value;
        newSymptomOptions[3] = filterWord(value, selectListStore.data.symptom);
      }

      setSymptoms(newSymptom);
      setSymptomOptions(newSymptomOptions);
    },
    [symptom, symptomOptions, selectListStore]
  );

  const handleTakingMedicine: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const { value } = event.target;

      if (event.target.name === 'hyperlipidemia') {
        setTakingMedicine({ ...takingMedicine, hyperlipidemia: value });
      }
      if (event.target.name === 'diabetes') {
        setTakingMedicine({ ...takingMedicine, diabetes: value });
      }
      if (event.target.name === 'blood-pressure') {
        setTakingMedicine({ ...takingMedicine, bloodPressure: value });
      }
    },
    [takingMedicine]
  );

  const handleThreshold = useCallback((event: Event, value: number | number[]) => {
    if (!Array.isArray(value)) {
      setThreshold(value);
    }
  }, []);

  const handleCancleButton: React.MouseEventHandler<HTMLButtonElement> = () => {
    window.location.reload();
  };

  const verifyInferenceBodyType = (formData: PredictionFormData): boolean => {
    if (!formData.userInfo.gender) {
      alert('성별을 체크해주세요');
      return false;
    }
    if (!formData.userInfo.age) {
      alert('연령을 입력해주세요');
      return false;
    }
    if (!formData.userInfo.bodyType) {
      alert('체형을 입력해주세요');
      return false;
    }

    if (!formData.disease[0] || !formData.disease[1] || !formData.disease[2] || !formData.disease[3]) {
      alert('질병을 입력해주세요\n(없다면 없음을 입력해주세요)');
      return false;
    }

    if (!formData.symptom[0] || !formData.symptom[1] || !formData.symptom[2] || !formData.symptom[3]) {
      alert('증상을 입력해주세요\n(없다면 없음을 입력해주세요)');
      return false;
    }

    if (!formData.takingMedicine.hyperlipidemia) {
      alert('고지혈증약 복용 여부를 체크해주세요');
      return false;
    }
    if (!formData.takingMedicine.diabetes) {
      alert('당뇨약 복용 여부를 체크해주세요');
      return false;
    }
    if (!formData.takingMedicine.bloodPressure) {
      alert('혈압약 복용 여브를 체크해주세요');
      return false;
    }

    return true;
  };

  const createInferenceBody = (formData: PredictionFormData): InferenceApiBody => {
    const filterDisease = formData.disease.map(value => {
      if (value === '없음') return null;
      return value;
    });

    const filterSymptom = formData.symptom.map(value => {
      if (value === '없음') return null;
      return value;
    });

    const body: InferenceApiBody = {
      성별: formData.userInfo.gender,
      연령대: formData.userInfo.age,
      고객체형: formData.userInfo.bodyType,
      질환1: filterDisease[0],
      질환2: filterDisease[1],
      질환3: filterDisease[2],
      질환4: filterDisease[3],
      증상1: filterSymptom[0],
      증상2: filterSymptom[1],
      증상3: filterSymptom[2],
      증상4: filterSymptom[3],
      고지혈증: formData.takingMedicine.hyperlipidemia,
      당뇨: formData.takingMedicine.diabetes,
      혈압: formData.takingMedicine.bloodPressure,
      '추천 지수': formData.threshold * 0.01,
    };

    return body;
  };

  useEffect(() => {
    if (!selectListStore.data) return;

    setDiseaseOptions([
      selectListStore.data.disease,
      selectListStore.data.disease,
      selectListStore.data.disease,
      selectListStore.data.disease,
    ]);
    setSymptomOptions([
      selectListStore.data.symptom,
      selectListStore.data.symptom,
      selectListStore.data.symptom,
      selectListStore.data.symptom,
    ]);
  }, [selectListStore]);

  return loading ? (
    <LoadingWrapper>
      <Loading style={{ marginBottom: '2.7rem' }} />
      <LoadingMessage>개별 성분 추천 예측 결과를 로딩중입니다...</LoadingMessage>
      <BasicButton theme="back" onClick={handleCancleButton}>
        취소하고 되돌아가기
      </BasicButton>
    </LoadingWrapper>
  ) : (
    <Form {...props} onSubmit={handleSubmit}>
      <ResetButtonWrapper>
        <BasicButton type="button" className="reset-btn" theme="secondary" size="small" onClick={resetPage}>
          <ResetIcon width="14px" height="14px" className="reset-icon" style={{ marginRight: '0.35rem' }} />
          초기화
        </BasicButton>
      </ResetButtonWrapper>

      <UserInfoBox userInfo={userInfo} handleUserInfo={handleUserInfo} selectList={selectListStore.data} />

      <DiseaseBox disease={disease} handleDisease={handleDisease} selectList={diseaseOptions} />

      <SymptomBox symptom={symptom} handleSymptom={handleSymptom} selectList={symptomOptions} />

      <TakingMedicineBox takingMedicine={takingMedicine} handleTakingMedicine={handleTakingMedicine} />

      <ThresholdBox threshold={threshold} handleThreshold={handleThreshold} />

      <SubmitButtonWrapper>
        <BasicButton theme="prime" type="submit">
          <ForecastIcon width="18px" height="18px" className="submit-icon" />
          성분 바로 예측하기
        </BasicButton>
      </SubmitButtonWrapper>
    </Form>
  );
}

const UserInfoBox = React.memo(({ userInfo, selectList, handleUserInfo }: UserInfoProp) => (
  <TitleContainer title="고객 정보">
    <InputWrapper>
      <InputBox>
        <InputLabel>성별</InputLabel>
        <Radio id="male" name="gender" value="남" checked={userInfo.gender === '남'} onChange={handleUserInfo}>
          남
        </Radio>
        <Radio id="female" name="gender" value="여" checked={userInfo.gender === '여'} onChange={handleUserInfo}>
          여
        </Radio>
      </InputBox>

      <InputBox>
        <InputLabel>연령</InputLabel>
        <Select id="age" placeholder="선택" value={userInfo.age} options={selectList?.age} onChange={handleUserInfo} />
      </InputBox>

      <InputBox>
        <InputLabel>체형</InputLabel>
        <Select
          id="body-type"
          placeholder="선택"
          value={userInfo.bodyType}
          options={selectList?.bodyType}
          onChange={handleUserInfo}
        />
      </InputBox>
    </InputWrapper>
  </TitleContainer>
));

const DiseaseBox = React.memo(({ disease, selectList, handleDisease }: DiseaseProp) => (
  <TitleContainer title="질환">
    <InputWrapper>
      <InputBox>
        <InputLabel>질환 1</InputLabel>
        <SearchInput
          id="disease-1"
          className="prediction-search-input"
          value={disease[0]}
          options={selectList[0]}
          placeholder="검색해주세요"
          onChange={handleDisease}
        />
      </InputBox>

      <InputBox>
        <InputLabel>질환 2</InputLabel>
        <SearchInput
          id="disease-2"
          className="prediction-search-input"
          value={disease[1]}
          options={selectList[1]}
          placeholder="검색해주세요"
          onChange={handleDisease}
        />
      </InputBox>

      <InputBox>
        <InputLabel>질환 3</InputLabel>
        <SearchInput
          id="disease-3"
          className="prediction-search-input"
          value={disease[2]}
          options={selectList[2]}
          placeholder="검색해주세요"
          onChange={handleDisease}
        />
      </InputBox>

      <InputBox>
        <InputLabel>질환 4</InputLabel>
        <SearchInput
          id="disease-4"
          className="prediction-search-input"
          value={disease[3]}
          options={selectList[3]}
          placeholder="검색해주세요"
          onChange={handleDisease}
        />
      </InputBox>
    </InputWrapper>
  </TitleContainer>
));

const SymptomBox = React.memo(({ symptom, selectList, handleSymptom }: SymptomProp) => (
  <TitleContainer title="증상">
    <InputWrapper>
      <InputBox>
        <InputLabel>증상 1</InputLabel>
        <SearchInput
          id="symptom-1"
          className="prediction-search-input"
          value={symptom[0]}
          options={selectList[0]}
          placeholder="검색해주세요"
          onChange={handleSymptom}
        />
      </InputBox>

      <InputBox>
        <InputLabel>증상 2</InputLabel>
        <SearchInput
          id="symptom-2"
          className="prediction-search-input"
          value={symptom[1]}
          options={selectList[1]}
          placeholder="검색해주세요"
          onChange={handleSymptom}
        />
      </InputBox>

      <InputBox>
        <InputLabel>증상 3</InputLabel>
        <SearchInput
          id="symptom-3"
          className="prediction-search-input"
          value={symptom[2]}
          options={selectList[2]}
          placeholder="검색해주세요"
          onChange={handleSymptom}
        />
      </InputBox>

      <InputBox>
        <InputLabel>증상 4</InputLabel>
        <SearchInput
          id="symptom-4"
          className="prediction-search-input"
          value={symptom[3]}
          options={selectList[3]}
          placeholder="검색해주세요"
          onChange={handleSymptom}
        />
      </InputBox>
    </InputWrapper>
  </TitleContainer>
));

const TakingMedicineBox = React.memo(({ takingMedicine, handleTakingMedicine }: TakingMedicineProp) => (
  <TitleContainer title="복용중인 약">
    <InputWrapper>
      <InputBox>
        <InputLabel>고지혈증</InputLabel>
        <Radio
          id="hyperlipidemia-yes"
          name="hyperlipidemia"
          value="예"
          checked={takingMedicine.hyperlipidemia === '예'}
          onChange={handleTakingMedicine}
        >
          예
        </Radio>
        <Radio
          id="hyperlipidemia-no"
          name="hyperlipidemia"
          value="아니오"
          checked={takingMedicine.hyperlipidemia === '아니오'}
          onChange={handleTakingMedicine}
        >
          아니오
        </Radio>
      </InputBox>

      <InputBox>
        <InputLabel>당뇨</InputLabel>
        <Radio
          id="diabetes-yes"
          name="diabetes"
          value="예"
          checked={takingMedicine.diabetes === '예'}
          onChange={handleTakingMedicine}
        >
          예
        </Radio>
        <Radio
          id="diabetes-no"
          name="diabetes"
          value="아니오"
          checked={takingMedicine.diabetes === '아니오'}
          onChange={handleTakingMedicine}
        >
          아니오
        </Radio>
      </InputBox>

      <InputBox>
        <InputLabel>혈압</InputLabel>
        <Radio
          id="blood-pressure-yes"
          name="blood-pressure"
          value="예"
          checked={takingMedicine.bloodPressure === '예'}
          onChange={handleTakingMedicine}
        >
          예
        </Radio>
        <Radio
          id="blood-pressure-no"
          name="blood-pressure"
          value="아니오"
          checked={takingMedicine.bloodPressure === '아니오'}
          onChange={handleTakingMedicine}
        >
          아니오
        </Radio>
      </InputBox>
    </InputWrapper>
  </TitleContainer>
));

const ThresholdBox = React.memo(({ threshold, handleThreshold }: ThresholdProp) => (
  <TitleContainer title="확률 역치 설정">
    <InputWrapper>
      <InputBox>
        <InputLabel>추천 지수</InputLabel>
        <RangeSlider step={5} value={threshold} onChange={handleThreshold} />
      </InputBox>
    </InputWrapper>
  </TitleContainer>
));

export default PredictionForm;
