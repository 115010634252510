import React from 'react';
import BasicButton from 'components/atoms/BasicButton';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { UserInfoSection, RecommendSection, BackButtonWrapper } from './ResultTemplate.styled';

interface Props {
  userInfoSection: React.ReactNode;
  recommendSection: React.ReactNode;
}

function ResultTemplate({ userInfoSection, recommendSection }: Props) {
  return (
    <>
      <BackButtonWrapper>
        <BasicButton theme="secondary" size="small" link="/prediction">
          <BackIcon width="14px" height="14px" className="back-icon" style={{ marginRight: '0.35rem' }} />
          예측으로 돌아가기
        </BasicButton>
      </BackButtonWrapper>

      <UserInfoSection>{userInfoSection}</UserInfoSection>
      <RecommendSection>{recommendSection}</RecommendSection>
    </>
  );
}

export default ResultTemplate;
