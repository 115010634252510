import React from 'react';

import { PredictionFormSection } from './PredictionFormTemplate.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  formSection: React.ReactNode;
}

function PredictionFormTemplate({ formSection, ...props }: Props): React.ReactElement {
  return <PredictionFormSection {...props}>{formSection}</PredictionFormSection>;
}

export default PredictionFormTemplate;
