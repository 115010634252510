import { INIT_SELECT_LIST_DATA, SET_SELECT_LIST_DATA, ERROR_SELECT_LIST_DATA } from 'actions/selectlist';

/* global SelectListState, InitSelectListAction, SetSelectListAction, ErrorSelectListAction */

const initState: SelectListState = {
  state: 'prepare',
};

type SelectListActions = InitSelectListAction | SetSelectListAction | ErrorSelectListAction;

const selectListReducer = (state = initState, actions: SelectListActions): SelectListState => {
  switch (actions.type) {
    case INIT_SELECT_LIST_DATA:
      return { ...state, state: 'loading' };
    case SET_SELECT_LIST_DATA:
      return { ...state, state: 'complete', data: actions.payload };
    case ERROR_SELECT_LIST_DATA:
      return { ...state, state: 'error' };
    default:
      return state;
  }
};

export default selectListReducer;
