import styled from 'styled-components';

export const UserInfoSection = styled.section`
  margin-bottom: 2.5rem;
`;

export const RecommendSection = styled.section`
  margin-bottom: 2.5rem;
`;

export const BackButtonWrapper = styled.div`
  margin-bottom: 0.5rem;

  .back-icon {
    fill: #fff;
  }
`;
