import React from 'react';
import { ContainerWrapper } from './Container.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: string;
  height?: string;
}

function Container({ children, ...props }: Props) {
  return <ContainerWrapper {...props}>{children}</ContainerWrapper>;
}

export default Container;
