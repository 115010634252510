import React, { useEffect, useState } from 'react';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
import { ReactComponent as IngredientIcon } from 'assets/icons/ingredient.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import {
  Table,
  TableHead,
  TableHeadInner,
  TableRow,
  TableData,
  IngredientName,
  IngredientValue,
} from './IngredientTable.styled';

/* global RecommendIngredient */

type SortState = 'asc' | 'desc';

interface Props extends React.TableHTMLAttributes<HTMLTableElement> {
  ingredientData: RecommendIngredient[];
}

function IngredientTable({ ingredientData, ...props }: Props) {
  const [sortedIngredientData, setSortedIngredientData] = useState(ingredientData);
  const [sortState, setSortState] = useState<SortState>('desc');

  const handleSortClick: React.MouseEventHandler<HTMLTableCellElement> = () => {
    let sortedData: RecommendIngredient[] = [];

    if (sortState === 'asc') {
      sortedData = sortedIngredientData.sort((a, b) => b.value - a.value);
      setSortState('desc');
    }

    if (sortState === 'desc') {
      sortedData = sortedIngredientData.sort((a, b) => a.value - b.value);
      setSortState('asc');
    }

    setSortedIngredientData(sortedData);
  };

  useEffect(() => {
    const sortedData = ingredientData.sort((a, b) => b.value - a.value);
    setSortedIngredientData(sortedData);
  }, [ingredientData]);

  return (
    <Table {...props}>
      <thead>
        <tr>
          <TableHead>
            <TableHeadInner>추천 성분</TableHeadInner>
          </TableHead>

          <TableHead onClick={handleSortClick}>
            <TableHeadInner>
              추천 지수
              <SortIcon className="sort-icon" />
            </TableHeadInner>
          </TableHead>
        </tr>
      </thead>

      <tbody>
        {sortedIngredientData.map(ingredient => (
          <TableRow key={ingredient.name}>
            <TableData>
              <IngredientName>
                <IngredientIcon />
                {ingredient.name}
              </IngredientName>
            </TableData>
            <TableData>
              <IngredientValue>
                <ProgressBar percent={ingredient.value} />
              </IngredientValue>
            </TableData>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}

export default IngredientTable;
