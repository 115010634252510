import { useEffect, useMemo, useState } from 'react';

export function useFadeUp(delay = 0): { className: string } {
  const [fadeState, setFadeState] = useState(false);
  const className: string = useMemo(() => ['fade-up-animation', fadeState && 'fade-up'].join(' '), [fadeState]);

  useEffect(() => {
    function animation(time: number): void {
      if (time > delay) {
        setFadeState(true);
        return;
      }
      requestAnimationFrame(animation);
    }

    requestAnimationFrame(animation);
  }, [delay]);

  return { className };
}
